'use client';
import React from 'react';
import FooterlLinkLists from './FooterlLinkLists';

const platform = process.env.NEXT_PUBLIC_PLATFORM;
const baseDomain = platformDomainSelector(platform);

function platformDomainSelector(platform) {
  switch (platform) {
    case 'karePedia': {
      return 'https://karepedia.com';
    }
    case 'mykareBlog': {
      return 'https://mykarehealth.com';
    }
    case 'karetripBlog': {
      return 'https://karetrip.com';
    }
  }
}

const LegalList = [
  {
    title: 'Privacy Policy',
    link: `${baseDomain}/policy/privacyPolicy.html`,
  },
  {
    title: 'Refund & Cancellation Policy',
    link: `${baseDomain}/policy/refundAndCancellationPolicy.html`,
  },
  {
    title: 'Terms & Conditions',
    link: `${baseDomain}/policy/termsAndConditions.html`,
  },
  {
    title: 'Disclaimer',
    link: `${baseDomain}/policy/disclaimerPolicy.html`,
  },
];

const FooterLegal = () => {
  return (
    <section className='max-sm:w-[60%]'>
      <h2 className='mb-[25px]  text-[16px] font-medium text-[#fff]'>Legal</h2>
      {/* {LeagalList?.map((list) => (
        <section
          key={list?.id}
          onClick={() => window.open(list.link, '_blank')}
          className='cursor-pointer text-[13px] leading-[2.46] text-white'
        >
          {list?.label} */}
      {/* </section> */}
      {/* ))} */}
      <FooterlLinkLists data={LegalList} targetBlank />
    </section>
  );
};

export default FooterLegal;

'use client';
// import Image from 'next/image';
// import { useRouter } from 'next/navigation';
// import { getStrapiMedia } from '@/lib/media';
import Link from 'next/link';
import { categoryIcons } from './CategoryIcon';

export default function NavCategoryMob(props: {
  setIsPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  attributes: { slug: string; name: string };
  border: string;
}) {
  const category = props?.attributes?.slug;
  return (
    <Link
      href={`/search/${category}`}
      className={`my-[10px] flex cursor-pointer items-center gap-[10px] sm:w-auto ${props?.border}`}
      onClick={() => props?.setIsPopupOpen(false)}
    >
      {/* <Image
        src={getStrapiMedia(props.attributes?.icon)}
        alt={''}
        width={20}
        height={20}
        className='h-[20px] me-3'
      /> */}
      <section className='flex w-[20px] justify-center'>
        {categoryIcons[props?.attributes?.name]
          ? categoryIcons[props?.attributes?.name]
          : categoryIcons?.ForYou}
      </section>
      <p className=' flex-shrink-0 whitespace-nowrap text-[15px] font-[600] text-[#1c2534] dark:text-[#ffffff]'>
        {props?.attributes?.name}
      </p>
    </Link>
  );
}

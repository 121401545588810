import React from 'react';

interface SearchBarProps {
  // onClick: () => void;
  search: string;
  setSearch: (value: string) => void;
}

function SearchBarMob({ search, setSearch }: SearchBarProps) {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  return (
    <section className='flex w-full'>
      <input
        type='text'
        onChange={handleInputChange}
        value={search}
        width={20}
        height={20}
        placeholder='Find articles on healthcare...'
        className='h-auto w-full bg-transparent text-[13px] font-medium text-[#1c2534] placeholder:text-[13px] placeholder:text-[#1c2534] focus:outline-none dark:text-[#fff] dark:placeholder:text-[#818b91]'
      />
    </section>
  );
}

export default SearchBarMob;

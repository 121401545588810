import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/karepedia/components/FooterLegal.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/karepedia/components/FooterSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/karepedia/components/NavBar/compoents/BookConsultationNow.tsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/components/NavBar/compoents/Drawer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["KarePediaLogo"] */ "/home/ubuntu/karepedia/components/NavBar/compoents/KarePediaLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/karepedia/components/NavBar/compoents/NavCategoryDesktop.tsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/components/NavBar/compoents/NotificationMob.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/karepedia/components/NavBar/compoents/NotificationSection.tsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/components/NavBar/compoents/SearchMob.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/karepedia/components/NavBar/compoents/SearchPopup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/ubuntu/karepedia/components/TostMessageCompoent.tsx");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/ubuntu/karepedia/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"300\",\"400\",\"500\",\"600\",\"700\"],\"style\":\"normal\"}],\"variableName\":\"poppins\"}");

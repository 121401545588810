'use client';
import React from 'react';
import Image from 'next/image';
import { tick } from '@/public';
import useThemeStore from '@/app/stores/useThemeStore';
const TostMessageCompoent = () => {
  const { copyUrlToast }: any = useThemeStore((state) => state);

  return (
    <>
      {copyUrlToast && (
        <div className='absolute right-[0px] top-[0px] z-[100] flex  w-[330px] items-center justify-start gap-[10px] bg-white p-[20px] shadow-2xl dark:bg-[#071b29] sm:right-[100px] sm:top-[30px]'>
          <Image
            width={20}
            height={20}
            src={tick}
            alt=''
            className='h-[20px] w-[20px]'
          />
          <p className='text-[20px] font-medium dark:text-white'>
            URL copied to clipboard!
          </p>
        </div>
      )}
    </>
  );
};

export default TostMessageCompoent;

'use client     ';
import React from 'react';
import { useEffect } from 'react';
import useThemeStore from '@/app/stores/useThemeStore';
import { applyThemePreference } from '@/lib/applyThemePreference';
import { HalfMoonIcon, SunIcon } from '@/components/SvgFiles';

export const ToggleButton = () => {
  const toggleTheme = useThemeStore((state: any) => state.toggleTheme);
  const theme = useThemeStore((state: any) => state.theme);

  useEffect(() => {
    applyThemePreference(theme);
  }, [theme]);

  return (
    <section className='flex h-[40px] items-center justify-center rounded-[5px] bg-[#EFEFEF] px-[4px] dark:bg-[#263843] max-sm:h-[30px] '>
      <button
        onClick={toggleTheme}
        type='button'
        className='h-[32px] w-[32px] rounded bg-white dark:bg-[#263843] max-sm:h-[20px]  max-sm:w-[20px] '
      >
        <span className='sr-only'>Light theme</span>
        <SunIcon className={' fill-black dark:fill-[#858585]'} />
      </button>
      <button
        onClick={toggleTheme}
        type='button'
        className='h-[32px] w-[32px] rounded bg-[#EFEFEF] dark:bg-white max-sm:h-[20px] max-sm:w-[20px] '
      >
        <span className='sr-only'>Dark theme</span>
        <HalfMoonIcon className={'fill-[#858585] dark:fill-black'} />
      </button>
    </section>
  );
};

import React, { ReactNode } from 'react';
import Link from 'next/link';
import { KarePediaLogo } from './KarePediaLogo';
import Image from 'next/image';

type Props = {
  isWhiteLogo?: Boolean;
};

export default function Logo({ isWhiteLogo }: Props) {
  const platform = process.env.NEXT_PUBLIC_PLATFORM;
  let Logo: ReactNode;
  switch (platform) {
    case 'karetripBlog':
      Logo = (
        <>
          <Image
            src={
              isWhiteLogo
                ? 'https://dxfqlcpql5f3t.cloudfront.net/logos/karetrip-logo-white.svg'
                : 'https://dxfqlcpql5f3t.cloudfront.net/logos/karetrip-logo.svg'
            }
            alt='karetrip-logo'
            className={'h-[30px] w-auto dark:hidden max-sm:h-[22px]'}
            width={100}
            height={100}
          />
          <Image
            src={`https://dxfqlcpql5f3t.cloudfront.net/logos/karetrip-logo-white.svg`}
            alt='karetrip-logo'
            className={'hidden h-[33px] w-auto dark:block max-sm:h-[22px]'}
            width={100}
            height={100}
          />
        </>
      );
      break;
    case 'mykareBlog':
      Logo = (
        <Image
          src={`https://mykare-logos.s3.ap-south-1.amazonaws.com/mykare/mykareLogo.png`}
          alt='Mykare-Logo'
          className={'h-[40px] w-auto max-sm:h-[30px]'}
          width={100}
          height={100}
        />
      );
      break;
    case 'karePedia':
      Logo = <KarePediaLogo isWhiteLogo={isWhiteLogo} />;
  }
  return (
    <section className='w-1/4 max-lg:w-full'>
      <Link href={'/'} className='h-[46px] w-auto cursor-pointer'>
        {Logo}
        <span className='sr-only'>Logo</span>
      </Link>
    </section>
  );
}

import {
  CardiologyIcon,
  CosmeticIcon,
  DiseaseIcon,
  FinancialWellnessIcon,
  HomeIcon,
  LaparoscopicIcon,
  NeurologyIcon,
  OncologyIcon,
  OphthalmologyIcon,
  OrthopaedicsIcon,
  ProctologyIcon,
  TransplantIcon,
  TravelIcon,
  UrologyIcon,
  VascularIcon,
  WellnessIcon,
} from '@/components/CategorySvgIcons';

export const categoryIcons: any = {
  ForYou: (
    <HomeIcon
      className={'m-auto '}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Laparoscopic: (
    <LaparoscopicIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Urology: (
    <UrologyIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Cosmetic: (
    <CosmeticIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Ophthalmology: (
    <OphthalmologyIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Proctology: (
    <ProctologyIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Vascular: (
    <VascularIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Wellness: (
    <WellnessIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Oncology: (
    <OncologyIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Cardiology: (
    <CardiologyIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Orthopaedics: (
    <OrthopaedicsIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  'Neurology ': (
    <NeurologyIcon
      className={'group m-auto'}
      color={'fill-[#1c2534] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'}
    />
  ),
  Transplant: (
    <TransplantIcon
      className={'group m-auto'}
      color={
        'stroke-[#1c2534] dark:stroke-[#9ea8ac] group-hover:stroke-[#00b4b9]'
      }
    />
  ),
  'Medical Travel': (
    <TravelIcon
      className={'group m-auto'}
      color={
        'stroke-[#1c2534] dark:stroke-[#9ea8ac] group-hover:stroke-[#00b4b9] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'
      }
    />
  ),
  'Financial Wellness': (
    <FinancialWellnessIcon
      className={'group m-auto'}
      color={
        'stroke-[#1c2534] dark:stroke-[#9ea8ac] group-hover:stroke-[#00b4b9] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'
      }
    />
  ),
  Disease: (
    <DiseaseIcon
      className={'group m-auto'}
      color={
        'stroke-[#1c2534] dark:stroke-[#9ea8ac] group-hover:stroke-[#00b4b9] dark:fill-[#9ea8ac] group-hover:fill-[#00b4b9]'
      }
    />
  ),
};

'use client';
import React, { useState, useRef, useEffect } from 'react';
import Image from 'next/image';

import { getStrapiMedia } from '@/lib/media';
import { getSearchResult } from '@/app/services/ArticleService';
import { searchPopup } from '@/types';

import SearchBar from './SearchBar';
import Link from 'next/link';
import { NoDataFound } from '@/components/NoData';

export interface DataItem {
  attributes: {
    title: string;
    card_image: {};
    slug: string;
  };
}

function SearchPopup() {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setShowPopup((prev) => !prev);
        setSearch(() => '');
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const keywords = search.split(' ');
    getSearchResult(keywords[0] ?? '', keywords[1] ?? '', keywords[2] ?? '')
      .then((data) => {
        setData(() => data?.data);
      })
      .catch((error) => {
        console.error(
          '======= getSearchResultError in SearchPop ========',
          error.message
        );
      });
  }, [search]);

  return (
    <section className='relative flex w-1/2 justify-center '>
      <section className='relative'>
        <SearchBar
          onClick={() => setShowPopup((prev) => !prev)}
          setSearch={setSearch}
          search={search}
          setShowPopup={setShowPopup}
        />
        {showPopup && (
          //popup section
          <section
            ref={popupRef}
            onMouseLeave={() => setShowPopup((prev) => !prev)}
            className='absolute top-[55px] z-10 max-h-[400px] min-w-[500px] overflow-y-auto rounded-[10px] bg-white p-[15px] drop-shadow-[0px_0px_35px_rgba(0,0,0,0.15)] dark:bg-[#263843]'
          >
            <section className='mb-[10px]'>
              <h4 className='text-[12px] text-gray-500 dark:text-white'>
                Topics
              </h4>
              {data.length ? (
                data.map((item: any) => (
                  <SearchPostdata
                    key={item?.id}
                    title={item?.attributes?.title}
                    image={getStrapiMedia(item?.attributes?.card_image)}
                    slug={item?.attributes?.slug}
                  />
                ))
              ) : (
                <p className='dark:text-white'>Search Not Found</p>
              )}
            </section>
            {/* <section className='mb-[10px]'>
            <h4 className='text-[12px] text-gray-500'>Publication</h4>
            <Postdata />
            <Postdata />
            <Postdata />
            </section>
            <section className='mb-[10px]'>
            <h4 className='text-[12px] text-gray-500'>People</h4>
            <Postdata />
            <Postdata />
            <Postdata />
          </section> */}
          </section>
        )}
      </section>
    </section>
  );
}

export default SearchPopup;

export const SearchPostdata = (props: searchPopup) => {
  return (
    <Link
      href={`/${props?.slug}`}
      className='m my-[10px] flex cursor-pointer overflow-hidden'
    >
      <Image
        width={25}
        height={25}
        src={props.image}
        alt='feature image'
        className='h-[25px] w-[25px] rounded-[150px]'
      />
      <p className='mx-[7px] my-auto line-clamp-1 overflow-hidden font-semibold dark:text-[#fff] max-2xl:text-[12px] 2xl:text-[12px]'>
        {props?.title ? props?.title : <NoDataFound />}
      </p>
    </Link>
  );
};

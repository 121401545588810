'use client';
import React, { useRef } from 'react';
import NavCategoryIcon from './NavCategoryIcon';
import ArrowScroll from '@/components/ArrowScroll';

type Props = { treatmentMenu: {}[] };

const NavCategoryDesktop = ({ treatmentMenu }: Props) => {
  const arrowLeftRef = useRef(null);
  const arrowRightRef = useRef(null);
  const containerRef = useRef(null);
  return (
    <section
      ref={containerRef}
      className='scrollbar-hidden-nav mr-[20px] flex overflow-x-scroll pb-[10px] '
    >
      {!!treatmentMenu?.length &&
        treatmentMenu?.map((item: any, index: number) =>
          index === 0 ? (
            <React.Fragment key={item?.attributes?.name}>
              <NavCategoryIcon {...item} />
              <section className='mr-[40px] mt-auto h-[60px] border-r border-r-[#e4e9f3]'></section>
            </React.Fragment>
          ) : (
            <NavCategoryIcon key={item?.id} {...item} />
          )
        )}
      <div ref={arrowRightRef}></div>
      <div ref={arrowLeftRef}></div>
      {treatmentMenu?.length > 9 && (
        <>
          <div className='absolute right-[240px] bg-gradient-to-r from-[#fff0] to-[#0000008e] dark:from-[#fff0] dark:to-[#ffffffb0]  2xl:hidden'>
            <ArrowScroll
              containerRef={containerRef}
              elementRef={arrowRightRef}
              direction='right'
            />
          </div>
          <div className='absolute left-[0px] bg-gradient-to-r from-[#0000008e] to-[#fff0] dark:from-[#ffffffb0] 2xl:hidden '>
            <ArrowScroll
              containerRef={containerRef}
              elementRef={arrowLeftRef}
              direction='left'
            />
          </div>
        </>
      )}
    </section>
  );
};

export default NavCategoryDesktop;

'use client';
import React, { useEffect, useState } from 'react';

type Props = {
  elementRef: React.RefObject<HTMLDivElement>;
  containerRef: React.RefObject<HTMLDivElement>;
  direction: 'left' | 'right';
};

const ArrowScroll: React.FC<Props> = ({
  elementRef,
  containerRef,
  direction,
}) => {
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const scrollLeft = () => {
    if (containerRef.current && elementRef.current && direction === 'right') {
      containerRef.current.scrollBy({
        left: containerRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
    if (containerRef.current && elementRef.current && direction === 'left') {
      containerRef.current.scrollBy({
        left: -containerRef.current.offsetWidth,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && elementRef.current) {
        const container = containerRef.current;
        const isAtLeft = container.scrollLeft === 0;
        setShowLeftButton(!isAtLeft);
        setShowRightButton(isAtLeft);
      }
    };

    const container = containerRef.current;
    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, elementRef]);

  return (
    <>
      {showLeftButton && direction === 'left' && (
        <button onClick={scrollLeft} className='rotate-180'>
          <Icon />
        </button>
      )}
      {showRightButton && direction === 'right' && (
        <button onClick={scrollLeft}>
          <Icon />
        </button>
      )}
    </>
  );
};

export default ArrowScroll;

const Icon = () => (
  <svg
    width='64px'
    height='64px'
    viewBox='-2.4 -2.4 28.80 28.80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    stroke='#1C2534'
  >
    <g id='SVGRepo_bgCarrier' strokeWidth='0'></g>
    <g
      id='SVGRepo_tracerCarrier'
      strokeLinecap='round'
      strokeLinejoin='round'
    ></g>
    <g id='SVGRepo_iconCarrier'>
      <path
        d='M10 7L15 12L10 17'
        stroke='#000000'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></path>
    </g>
  </svg>
);

'use client';
import { useState } from 'react';
import { FormPopup } from '@/components/FormPopup';
import useThemeStore from '@/app/stores/useThemeStore';
import ToastMessage from '@/components/ToastMessage';
import { useGlobalStore } from '@/app/stores/useGlobalStore';

function BookConsultationNow() {
  const { ctaLabel, setCtaLabel } = useGlobalStore();
  const { showToast } = useThemeStore((state) => state);
  const [showFormPopup, setShowFormPopup] = useState(false);

  return (
    <div className='min-w-[220px]'>
      {showToast && <ToastMessage />}
      <FormPopup openPopup={showFormPopup} setOpenPopup={setShowFormPopup} />
      <button
        className='h-[40px] w-[200px] rounded-[8px] bg-[#00b4b9] p-[10px_0px] text-center text-[13px] font-normal tracking-[-.036px]
      text-[#fff] transition duration-200 ease-in-out hover:border hover:border-[#00b4b9]
      hover:bg-white hover:tracking-[-.50px] hover:text-[#1c2534] '
        onClick={() => {
          setShowFormPopup(true);
          setCtaLabel('Hero Section Navbar cta');
        }}
      >
        Book Consultation Now
      </button>
    </div>
  );
}

export default BookConsultationNow;

import React from 'react';
import { SearchIcon } from '@/components/SvgFiles';

interface SearchBarProps {
  onClick: () => void;
  search: string;
  setSearch: (value: string) => void;
  setShowPopup: (boolean: boolean) => void;
}

function SearchBar({
  onClick,
  search,
  setSearch,
  setShowPopup,
}: SearchBarProps) {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowPopup(true);
    setSearch(event.target.value);
  };

  return (
    <section
      onClick={onClick}
      className='flex min-w-[260px] rounded-[10px] bg-[#f2f8fc] p-[15px_16px] dark:bg-[#263843] sm:w-[500px] lg:mx-auto'
    >
      {/* <Image className='mr-[8px]' src={searchIcon} alt='search icon' /> */}
      <SearchIcon className={'fill-[#1c2534] dark:fill-[#818b91]'} />
      <input
        type='text'
        onChange={handleInputChange}
        value={search}
        width={20}
        height={20}
        placeholder='Find articles on healthcare...'
        className='h-auto w-full bg-transparent text-[13px] font-medium text-[#1c2534] placeholder:text-[13px] placeholder:text-[#1c2534] focus:outline-none dark:text-white dark:placeholder:text-[#818b91]'
      />
    </section>
  );
}

export default SearchBar;

'use client';
import React, { useState } from 'react';
import Logo from './Logo';
import { ToggleButton } from './ToggleButton';
import { CloseIcon, ToggleButtonIcon } from '@/components/SvgFiles';
import NavCategoryMob from './NavCategoryMob';
import FontChanger from './FontChanger';

function Drawer({ treatmentMenu }: any) {
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  return (
    <>
      <button className='p-0' onClick={() => setIsPopupOpen(true)}>
        <ToggleButtonIcon
          className={'mx-[5px] cursor-pointer'}
          color={'fill-[#1c2534] dark:fill-white'}
        />
        <span className='sr-only'>Menu</span>
      </button>
      {isPopupOpen && (
        <div className='absolute left-0 top-0 z-20 w-full transform bg-transparent px-[14px] '>
          <div className='rounded-b-lg bg-[#ffffff] drop-shadow-lg dark:border-x dark:border-b dark:border-[#45555E] dark:bg-[#071b29]'>
            <div className='flex items-center p-[12px]'>
              <div className='me-auto'>
                {' '}
                <Logo />
              </div>
              <div className='flex items-center gap-[15px]'>
                <FontChanger />
                <ToggleButton />
                <button onClick={() => setIsPopupOpen(false)} className='p-0'>
                  <CloseIcon
                    className={'h-[15px] fill-[#000] dark:fill-[#fff]'}
                    color={'fill-[#000] dark:fill-[#fff]'}
                  />
                  <span className='sr-only'>close button</span>
                </button>
              </div>
            </div>
            <hr className='border-[#eef1f8] dark:border-0' />
            <div className='p-[12px_22px]'>
              {treatmentMenu?.map((item: any) => (
                <NavCategoryMob
                  key={item?.id}
                  {...item}
                  setIsPopupOpen={setIsPopupOpen}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Drawer;

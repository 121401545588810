'use client';
import React, { useState } from 'react';
import useThemeStore from '@/app/stores/useThemeStore';

const FontChanger = () => {
  const setFontHeading = useThemeStore((state) => state.setFontHeading);
  // const font = useThemeStore((state) => state.fontHeading);

  // useEffect(() => {}, [font]);
  const [fontIcon, setFontIcon] = useState('small');
  const [showPopup, setShowPopup] = useState(false);
  const icons = {
    small: <SmallIcon />,
    medium: <MediumIcon />,
    large: <LargeIcon />,
  };
  return (
    <>
      <section
        onMouseLeave={() => setShowPopup(false)}
        onMouseEnter={() => setShowPopup(true)}
        onClick={() => setShowPopup(!showPopup)}
        className='relative'
      >
        <section className=' rounded-[6px] border border-[#eaedf3] bg-white p-[4px_9px] text-center font-semibold dark:border-none dark:bg-[#263843]'>
          {icons[fontIcon]}
        </section>
        {showPopup && (
          <section className=' absolute right-0 top-[38px] z-10 pt-[10px]'>
            <section className='flex w-[100px] flex-col items-start justify-center  gap-[5px] rounded-[12px] bg-white p-[10px] shadow-[0px_3px_15px_rgba(0,0,0,0.25)] dark:border dark:border-[#45555E] dark:bg-[#071b29]'>
              <button
                className=' flex w-full items-center justify-between text-[12px] dark:text-white'
                onClick={() => {
                  setFontHeading(`small`);
                  setFontIcon(`small`);
                }}
              >
                Normal
                {fontIcon === 'small' && (
                  <section className='ml-[10px] h-[8px] w-[8px] rounded-full bg-green-500'></section>
                )}
              </button>
              <button
                className='flex  w-full items-center justify-between text-[14px] dark:text-white'
                onClick={() => {
                  setFontHeading('medium');
                  setFontIcon(`medium`);
                }}
              >
                Medium
                {fontIcon === 'medium' && (
                  <section className='ml-[10px] h-[8px] w-[8px] rounded-full bg-green-500'></section>
                )}
              </button>
              <button
                className='flex  w-full items-center justify-between text-[16px] dark:text-white'
                onClick={() => {
                  setFontHeading(`large`);
                  setFontIcon(`large`);
                }}
              >
                Large
                {fontIcon === 'large' && (
                  <section className='ml-[10px] h-[8px] w-[8px] rounded-full bg-green-500'></section>
                )}
              </button>
            </section>
          </section>
        )}
      </section>
    </>
  );
};

export default FontChanger;

const SmallIcon = () => (
  <p className='w-[20px] text-[20px] font-bold dark:text-white'>
    A
    <span className='ml-[-3px] text-[12px] font-medium dark:text-white'>A</span>
  </p>
);
const MediumIcon = () => (
  <p className='w-[20px] text-[20px] font-bold dark:text-white'>M</p>
);
const LargeIcon = () => (
  <p className='w-[20px] text-[20px] font-bold dark:text-white'>L</p>
);

const baseUrl = process.env.NEXT_PUBLIC_API_DB_URL;

export const saveSubscription = async (postData: object) => {
  try {
    const response = await fetch(`${baseUrl}/enquiry/subscription`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    });
    return response;
  } catch (error) {
    console.error('===saveEnquiry error===', error);
  }
};

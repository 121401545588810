'use client';
import { saveSubscription } from '@/app/services/saveSubcription';
import useThemeStore from '@/app/stores/useThemeStore';

import React, { useState } from 'react';

function FooterSearch() {
  const [email, setEmail] = useState('');
  const [isvalid, setIsvalid] = useState(Boolean);
  const { setShowToast } = useThemeStore((state) => state);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleSubmit = async (event: any) => {
    event?.preventDefault();
    if (isvalid) {
      const postData = {
        email,
        platform: 'MYKARE_WEB',
      };
      await saveSubscription(postData)
        .then((res) => {
          setEmail(() => '');
          if (res.status === 200) {
            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, 1500);
          }
        })
        .catch((e) => console.error(e.message));
    }
  };
  const handleChange = (event) => {
    setEmail(() => event.target.value);
    if (validateEmail(event.target.value)) {
      setIsvalid(true);
    } else {
      setIsvalid(false);
    }
  };

  return (
    <section>
      <section className='  relative flex h-[] min-w-full items-center sm:w-full'>
        <section
          className={
            'flex h-[40px] min-w-full rounded-[50px] bg-[#474958]  pl-[15px] sm:h-[56px]  sm:w-full '
          }
        >
          <input
            type='email'
            value={email}
            onChange={handleChange}
            placeholder='Enter your email'
            className={`w-full bg-transparent  text-[13px] font-medium text-[#d0d6ec] placeholder:text-[14px] placeholder:text-[#d0d6ec] focus:outline-none`}
          />
          <button
            type='submit'
            onClick={handleSubmit}
            className='ml-auto  rounded-[50px] bg-[#00cfaf]  p-[8px_20px] text-[14px] font-medium text-[#1c2534] sm:p-[10px_15px] '
          >
            Subscribe
          </button>
        </section>
      </section>
      {!isvalid && email && (
        <p className='absolute ml-[30px] text-red-500 '>Enter a vaild email</p>
      )}
    </section>
  );
}

export default FooterSearch;

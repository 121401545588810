import Link from 'next/link';
import React from 'react';
import { NoDataFound } from './NoData';

type Props = {
  data: {
    title: string | any;
    link: string | any;
  }[];
  targetBlank?: boolean;
};

function FooterlLinkLists({ data, targetBlank }: Props) {
  // const data = [
  //   {
  //     id: 1,
  //     title: 'Circumcision',
  //     link: 'https://example.com/circumcision',
  //   },
  //   {
  //     id: 2,
  //     title: 'Kidney Stone',
  //     link: 'https://example.com/kidney-stone',
  //   },
  //   {
  //     id: 3,
  //     title: 'Piles Surgery',
  //     link: 'https://example.com/piles-surgery',
  //   },
  //   {
  //     id: 4,
  //     title: 'Gastroenterology',
  //     link: 'https://example.com/gastroenterology',
  //   },
  // ];
  return (
    <section className=' flex flex-col'>
      {data?.length ? (
        data.map((item: any) => (
          <Link
            key={item?.attributes?.name ?? item?.title}
            className='mb-[10px] text-[13px] text-[#d0d6ec]'
            href={
              item?.attributes
                ? `/search/${item?.attributes?.slug}`
                : item?.link
            }
            target={targetBlank ? '_blank' : '_parent'}
          >
            {item?.attributes?.name ?? item?.title}
          </Link>
        ))
      ) : (
        <></>
      )}
    </section>
  );
}

export default FooterlLinkLists;

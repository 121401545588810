'use client';
import React, { useEffect, useState } from 'react';
import { DataItem } from './SearchPopup';
import { getRecently } from '@/app/services/ArticleService';
import { getStrapiMedia } from '@/lib/media';
import dynamic from 'next/dynamic';
const CloseIcon = dynamic(
  () => import('@/components/SvgFiles').then((module) => module.CloseIcon),
  {
    ssr: false,
  }
);
const Notification = dynamic(
  () => import('@/components/SvgFiles').then((module) => module.Notification),
  {
    ssr: false,
  }
);
const PostdataRead = dynamic(
  () => import('./NotificationSection').then((module) => module.PostdataRead),
  {
    ssr: false,
  }
);

const NotificationMob = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    getRecently()
      .then((res) => {
        setData(() => res?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <>
      <button className='p-0' onClick={() => setIsPopupOpen(true)}>
        <Notification
          className={'cursor-pointer fill-[#1c2534] dark:fill-white'}
          color=''
        />
        <span className='sr-only'>Notification Button</span>
      </button>
      {isPopupOpen && (
        <div
          className='absolute left-1/2 top-0 z-20 flex w-full -translate-x-1/2 transform items-center justify-center'
          onClick={() => setIsPopupOpen(false)}
        >
          <div className='px-[14px] drop-shadow-lg'>
            <div className='flex items-center justify-center overflow-y-scroll rounded-b-lg bg-[#fff] p-[14px] dark:border-x dark:border-b dark:border-[#45555E] dark:bg-[#071b29]'>
              <div className='my-[10px]'>
                <div className='flex'>
                  <p className='me-auto text-[14px] text-gray-500 dark:text-white'>
                    Notifications
                  </p>
                  <button onClick={() => setIsPopupOpen(false)} className='p-0'>
                    <CloseIcon
                      className={'h-[15px] fill-[#000] dark:fill-[#fff]'}
                      color={'fill-[#000] dark:fill-[#fff]'}
                    />
                    <span className='sr-only'>close Button</span>
                  </button>
                </div>
                {data.length ? (
                  data.map((item, ind) => (
                    <PostdataRead
                      key={`postdata:${ind}`}
                      title={item?.attributes?.title}
                      image={getStrapiMedia(item?.attributes?.card_image)}
                      slug={item?.attributes?.slug}
                      border={ind !== 4 ? `border-b` : ``}
                    />
                  ))
                ) : (
                  <p>Search Not Found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default NotificationMob;

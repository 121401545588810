'use client';
import { CloseIcon, SearchIcon } from '@/components/SvgFiles';
import React, { useEffect, useRef, useState } from 'react';
import { SearchPostdata } from './SearchPopup';
import { getStrapiMedia } from '@/lib/media';
import { getSearchResult } from '@/app/services/ArticleService';
import SearchBarMob from './SearchBarMob';

export interface DataItem {
  attributes: {
    title: string;
    card_image: {};
    slug: string;
  };
}
const SearchMob = () => {
  const [isSearchOpen, setSearchOpen] = useState(false);
  // const popupRef = useRef<HTMLDivElement>(null);
  const [search, setSearch] = useState('');
  const [data, setData] = useState<DataItem[]>([]);

  useEffect(() => {
    const keywords = search.split(' ');
    getSearchResult(keywords[0] ?? '', keywords[1] ?? '', keywords[2] ?? '')
      .then((data) => {
        setData(() => data?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [search]);

  return (
    <>
      <button
        className='p-0'
        onClick={() => {
          setSearchOpen(true);
        }}
      >
        <SearchIcon
          className={'cursor-pointer fill-[#1c2534] dark:fill-white'}
          color=''
        />
        <span className='sr-only'>Search Icon</span>
      </button>
      {isSearchOpen && (
        <div className='absolute left-0 top-0 z-20 flex w-full transform flex-col items-center justify-center '>
          <div className='flex h-[75px] w-full items-center justify-center bg-[#fff] px-[14px] dark:bg-[#1c2534]'>
            <div className='flex items-center justify-center'>
              <SearchIcon
                className={'cursor-pointer fill-[#1c2534] dark:fill-white'}
                color=''
              />
            </div>
            <div className='w-full'>
              <section className='relative'>
                <SearchBarMob setSearch={setSearch} search={search} />
              </section>
            </div>
            <div className='flex items-center justify-center'>
              <button onClick={() => setSearchOpen(false)} className='p-0'>
                <CloseIcon
                  className={'h-[15px] fill-[#000] dark:fill-[#fff]'}
                  color={'fill-[#000] dark:fill-[#fff]'}
                />
                <span className='sr-only'>Close Button</span>
              </button>
            </div>
          </div>
          <div className='w-full px-[14px] drop-shadow-lg'>
            <div className='max-h-[300px] overflow-y-auto rounded-b-lg bg-[#ffffff] p-[15px] shadow-2xl dark:border dark:border-[#45555E] dark:bg-[#1c2534]'>
              <section className='mb-[10px]'>
                <h4 className='text-[12px] text-gray-500 dark:text-[#fff]'>
                  Topics
                </h4>
                {data.length ? (
                  data.map((item: any) => (
                    <SearchPostdata
                      key={item?.id}
                      title={item?.attributes?.title}
                      image={getStrapiMedia(item?.attributes?.card_image)}
                      slug={item?.attributes?.slug}
                    />
                  ))
                ) : (
                  <p className='dark:text-[#fff] max-2xl:text-[12px] 2xl:text-[12px]'>
                    Search Not Found
                  </p>
                )}
              </section>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SearchMob;

import Link from 'next/link';
import { categoryIcons } from './CategoryIcon';

export default function NavCategoryIcon(props: any) {
  const category = props.attributes?.slug;
  return (
    <Link
      className={`group box-border flex cursor-pointer flex-col items-center border-[#00b4b9] pt-[16px] hover:border-t-[3px] hover:pt-[13px] sm:mr-[20px] sm:w-auto xl:mr-[40px] ${props.border}`}
      href={`/search/${category}`}
    >
      <section className=' m-auto mb-[10px] h-[30px] w-[30px]'>
        {categoryIcons[props?.attributes?.name]
          ? categoryIcons[props?.attributes?.name]
          : categoryIcons?.ForYou}
      </section>

      <p className=' flex-shrink-0 whitespace-nowrap text-[13px] font-medium text-[#1c2534] dark:text-[#9ea8ac] dark:group-hover:text-white'>
        {props.attributes?.name}
      </p>
    </Link>
  );
}

'use client';
import React, { useEffect, useRef, useState } from 'react';
import { DataItem, SearchPostdata } from './SearchPopup';
import { getRecently } from '@/app/services/ArticleService';
import { consultationPopup } from '@/types';
import { getStrapiMedia } from '@/lib/media';
import { ToggleButton } from '@/components/NavBar/compoents/ToggleButton';
import FontChanger from './FontChanger';
import { Notification } from '@/components/SvgFiles';
import Link from 'next/link';

function NotificationSection() {
  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState<DataItem[]>([]);
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setShowPopup((prev) => !prev);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    getRecently()
      .then((res) => {
        setData(() => res?.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <section className='relative flex w-1/4 justify-end gap-[30px]'>
      <FontChanger />
      <ToggleButton />
      <button onClick={() => setShowPopup((prev) => !prev)} id='Notification'>
        <Notification
          className={'cursor-pointer fill-[#1c2534] dark:fill-white'}
        />
        <span className='sr-only'>Notification</span>
      </button>
      {showPopup && (
        <section
          onMouseLeave={() => setShowPopup((prev) => !prev)}
          ref={popupRef}
          className='absolute right-0 top-[35px] z-10 h-auto min-w-[400px]
           rounded-[10px] bg-white p-[15px] drop-shadow-[0px_0px_35px_rgba(0,0,0,0.15)] dark:bg-[#263843]'
        >
          <section className='mb-[10px]'>
            <h4 className='text-[12px] text-gray-500 dark:text-white'>
              Notifications
            </h4>

            {data.length ? (
              data.map((item, index) => (
                <PostdataRead
                  key={`notificatin:${index}`}
                  title={item?.attributes?.title}
                  image={getStrapiMedia(item?.attributes?.card_image)}
                  slug={item?.attributes?.slug}
                  border={index !== 4 ? `border-b` : ``}
                />
              ))
            ) : (
              <p>Search Not Found</p>
            )}
          </section>
        </section>
      )}
    </section>
  );
}

export default NotificationSection;

export const PostdataRead = (props: consultationPopup) => {
  return (
    <section
      className={`my-[10px] flex items-center justify-between border-b-gray-300 ${props.border}`}
    >
      <SearchPostdata
        title={props?.title}
        image={props?.image}
        slug={props?.slug}
      />
      <Link
        href={`/${props?.slug}`}
        className='ml-[15px] rounded-[15px] border-[1.5px] border-black p-[3px_13px] text-[12px] dark:border-white dark:text-white'
      >
        Read
      </Link>
    </section>
  );
};
